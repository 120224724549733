import React from 'react'
import styled from 'styled-components'
import { device } from './helpers/constants'

const HeroWrapper = styled.div`
  width: 130vw;
  height: 400px;
  left: -15vw;
  margin-top: ${((Math.tan(12) * 400) / 2)}px;
  position: relative;
  transform: skewY(-12deg);
  overflow: hidden;
  border-bottom-left-radius: 50vw;

  &.overlay {
    &--blue {
      background: linear-gradient(170deg, #12AEE5, #33DFDB);
    }
    &--green {
      background: linear-gradient(170deg, #00C085, #8CDD57);
    }
    &--pink {
      background: linear-gradient(170deg, #D277EF, #F1698D);
    }
    &--yellow {
      background: linear-gradient(170deg, #FFAE00, #F1D566);
    }
  }

  @media ${device.laptop} {
    height: 800px;
    margin-top: ${((Math.tan(12) * 800) / 2)}px;
    border-bottom-left-radius: 40vw;
  }
`

const HeroBackground = styled.div`
  width: 100vw;
  height: calc(100% + ${((Math.tan(-12) * 400) / 2)}px);
  position: absolute;
  left: 15vw;
  z-index: -1;
  transform: skewY(12deg);
  background: ${props => `url(${props.bg}) center center / cover no-repeat`};
  mix-blend-mode: multiply;

  @media ${device.laptop} {
    height: calc(100% + ${((Math.tan(-12) * 800) / 2)}px);
  }
`

const HeroContent = styled.div`
  color: white;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 170px;
  transform: translate(-50%, -50%);
  width: 90%;

  h1, h4 {
    color: inherit;
  }

  @media ${device.tablet} {
    width: auto;
    max-width: 740px;
  }

  @media ${device.laptop} {
    top: 380px;
  }
`

// const Bubble = styled.div`
//   height: 60vh;
//   width: 60vh;
//   border-radius: 100%;
//   position: absolute;
//   top: 30vw;
//   right: 15vw;
//   transform: rotate(12deg) skewY(12deg);
//   background: linear-gradient(to bottom right, rgba(255,255,255, 0), rgba(255,255,255, 0.5));
//   box-shadow: 0 80px 50px rgba(255,255,255, 0.25);
// `

export const HeroContainer = styled.div`
  @media ${device.laptop} {
    margin-bottom: 4vw;
  }
`

const Hero = ({ acf: { image, subtitle, title, overlay } }) => (
  image && image.localFile ? (
    <>
      <HeroWrapper className={`overlay--${overlay}`}>
        <HeroBackground bg={image.localFile.childImageSharp.fixed.src} />
        {/* <Bubble /> */}
      </HeroWrapper>

      <HeroContent>
        {subtitle && <h4>{subtitle}</h4>}
        {title && <h1>{title}</h1>}
      </HeroContent>
    </>
  ) : (
    null
  )
)

export default Hero
